import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { HashRouter as Router, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const MEASUREMENT_ID = "G-HC2VC7178N"; 

ReactGA.initialize(MEASUREMENT_ID);

function TrackPageView() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <TrackPageView />
    <App />
  </Router>
);