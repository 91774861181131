import React, { Suspense, lazy } from 'react';
import Navbar from './components/Navbar';
import { AuthProvider } from './contexts/AuthContext';
import { LearningProvider } from './contexts/LearningContext';
import { OverlayProvider } from './components/overlayManager';
import './styles/App.css';
import ScrollToTop from './ScrollToTop';
import Loader from './Loader'; // Ensure the correct path, e.g., './components/Loader' if needed

const AppRouter = lazy(() => import('./AppRouter'));

const App = () => {
  return (
    <AuthProvider>
      <OverlayProvider>
        <LearningProvider>
          <Navbar />
          <ScrollToTop />
          <Suspense fallback={<Loader />}>
            <AppRouter />
          </Suspense>
        </LearningProvider>
      </OverlayProvider>
    </AuthProvider>
  );
};

export default App;
