import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
} from "firebase/firestore";

// Securely load Firebase configuration from environment variables
const firebaseConfig = {
  apiKey: "AIzaSyA9fv5y23398kzP8HljS3BlreOjycA-NUI",
  authDomain: "anglomova-429e2.firebaseapp.com",
  databaseURL: "https://anglomova-429e2-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "anglomova-429e2",
  storageBucket: "anglomova-429e2.appspot.com",
  messagingSenderId: "30552534006",
  appId: "1:30552534006:web:74d0515d25b5778e30f5d7",
  measurementId: "G-9W633V0TWL"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firestore with persistent caching
const db = initializeFirestore(app, {
  localCache: persistentLocalCache(),
});

const auth = getAuth(app);

export { auth, db };