// src/contexts/LearningContext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  collection,
  doc,
  getDocs,
  getDoc,
  writeBatch,
  arrayUnion,
  setDoc,
  onSnapshot,
} from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { db } from '../firebase';

const LearningContext = createContext();
export const useLearning = () => useContext(LearningContext);

export const LearningProvider = ({ children }) => {
  const { user, loading: authLoading } = useAuth();

  // Course data state
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [nextLesson, setNextLesson] = useState(null);
  const [nextWorkbook, setNextWorkbook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Courses list state
  const [courses, setCourses] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [coursesError, setCoursesError] = useState(null);
  const coursesFetched = useRef(false);

  // NEW: Local state for real-time user data (finished arrays and purchase totals)
  const [userCourseData, setUserCourseData] = useState({
    finishedLessons: [],
    finishedWorkbooks: [],
    totalLessonsPurchased: 0,
    totalWorkbooksPurchased: 0,
  });

  // State for user interactions & current content ID
  const [userInteractions, setUserInteractions] = useState({});
  const [currentLessonOrWorkbookID, setCurrentLessonOrWorkbookID] = useState(null);

  // Vault materials state
  const [materials, setMaterials] = useState([]);

  // -----------------------------------------------------
  // 1. Real-time listener for the user document
  // -----------------------------------------------------
  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const unsubscribe = onSnapshot(
        userDocRef,
        (docSnap) => {
          if (docSnap.exists()) {
            setUserCourseData(docSnap.data());
          }
        },
        (err) => {
          console.error('Error listening to user document:', err);
        }
      );
      return () => unsubscribe();
    }
  }, [user]);

  // -----------------------------------------------------
  // 2. Fetch course (modules, lessons, workbooks) data
  // -----------------------------------------------------
  const fetchUserAndCourseData = useCallback(async () => {
    if (!user) return;

    setLoading(true);
    setError(null);

    try {
      if (user.courseID) {
        const courseDocRef = doc(db, 'courses', user.courseID);
        const courseDoc = await getDoc(courseDocRef);
        if (courseDoc.exists()) {
          const courseData = { id: courseDoc.id, ...courseDoc.data() };

          // Fetch modules
          const modulesSnapshot = await getDocs(collection(courseDocRef, 'modules'));
          const modulesData = await Promise.all(
            modulesSnapshot.docs.map(async (moduleDoc) => {
              const moduleData = { id: moduleDoc.id, ...moduleDoc.data() };

              // Fetch lessons in the module
              const lessonsSnapshot = await getDocs(
                collection(courseDocRef, 'modules', moduleDoc.id, 'lessons')
              );
              const lessonsData = await Promise.all(
                lessonsSnapshot.docs.map(async (lessonDoc) => {
                  const lessonData = {
                    id: lessonDoc.id,
                    moduleID: moduleDoc.id,
                    ...lessonDoc.data(),
                  };
                  // Fetch user interactions for the lesson
                  const interactionRef = doc(
                    db,
                    'courses',
                    user.courseID,
                    'modules',
                    moduleDoc.id,
                    'lessons',
                    lessonDoc.id,
                    'interactions',
                    user.uid
                  );
                  const interactionDoc = await getDoc(interactionRef);
                  lessonData.userInteractions = interactionDoc.exists()
                    ? interactionDoc.data()
                    : {};
                  return lessonData;
                })
              );

              // Fetch workbooks in the module
              const workbooksSnapshot = await getDocs(
                collection(courseDocRef, 'modules', moduleDoc.id, 'workbooks')
              );
              const workbooksData = await Promise.all(
                workbooksSnapshot.docs.map(async (workbookDoc) => {
                  const workbookData = {
                    id: workbookDoc.id,
                    moduleID: moduleDoc.id,
                    ...workbookDoc.data(),
                  };
                  // Fetch user interactions for the workbook
                  const interactionRef = doc(
                    db,
                    'courses',
                    user.courseID,
                    'modules',
                    moduleDoc.id,
                    'workbooks',
                    workbookDoc.id,
                    'interactions',
                    user.uid
                  );
                  const interactionDoc = await getDoc(interactionRef);
                  workbookData.userInteractions = interactionDoc.exists()
                    ? interactionDoc.data()
                    : {};
                  return workbookData;
                })
              );

              return {
                ...moduleData,
                lessons: lessonsData,
                workbooks: workbooksData,
              };
            })
          );

          const fullCourseData = {
            ...courseData,
            modules: modulesData,
          };

          setSelectedCourse(fullCourseData);
        } else {
          setError('Selected course does not exist.');
        }
      }
    } catch (err) {
      console.error('Error fetching course data:', err);
      setError('Failed to fetch course data.');
    } finally {
      setLoading(false);
    }
  }, [user]);

  // -----------------------------------------------------
  // 3. Recalculate next available lesson and workbook
  //    when either course data or userCourseData changes
  // -----------------------------------------------------
  useEffect(() => {
    if (selectedCourse && userCourseData) {
      const modulesData = selectedCourse.modules || [];
      const allLessons = modulesData.flatMap((m) => m.lessons || []);
      const allWorkbooks = modulesData.flatMap((m) => m.workbooks || []);
      const finishedLessons = userCourseData.finishedLessons || [];
      const finishedWorkbooks = userCourseData.finishedWorkbooks || [];
      const unfinishedLessons = allLessons.filter(
        (lesson) => !finishedLessons.some((f) => f.lessonID === lesson.id)
      );
      const unfinishedWorkbooks = allWorkbooks.filter(
        (workbook) => !finishedWorkbooks.some((f) => f.workbookID === workbook.id)
      );
      setNextLesson(unfinishedLessons[0] || null);
      setNextWorkbook(unfinishedWorkbooks[0] || null);
    }
  }, [selectedCourse, userCourseData]);

  // -----------------------------------------------------
  // 4. Save interactions immediately (with debounce)
  // -----------------------------------------------------
  const saveInteractionsImmediately = async () => {
    if (!user || !currentLessonOrWorkbookID) return;
    try {
      const [type, moduleID, contentID] = currentLessonOrWorkbookID.split('/');
      const interactionRef = doc(
        db,
        'courses',
        user.courseID,
        'modules',
        moduleID,
        type === 'lesson' ? 'lessons' : 'workbooks',
        contentID,
        'interactions',
        user.uid
      );
      await setDoc(interactionRef, userInteractions, { merge: true });
    } catch (err) {
      console.error('Error saving user interactions:', err);
      setError('Failed to save interactions.');
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      saveInteractionsImmediately();
    }, 500);
    return () => clearTimeout(handler);
  }, [userInteractions, user, currentLessonOrWorkbookID]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveInteractionsImmediately();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [userInteractions, user, currentLessonOrWorkbookID]);

  const updateUserInteraction = (key, interaction) => {
    setUserInteractions((prev) => ({ ...prev, [key]: interaction }));
  };

  // -----------------------------------------------------
  // 5. Finish a lesson (update finishedLessons in user doc)
  // -----------------------------------------------------
  const finishLesson = async (lessonID, lessonStats = {}) => {
    try {
      if (!user || !lessonID) return;
      const [moduleID, contentID] = lessonID.split('/');
  
      // Prevent duplicate finish using real-time userCourseData
      if (
        userCourseData.finishedLessons &&
        userCourseData.finishedLessons.some((l) => l.lessonID === contentID)
      ) {
        console.log('Lesson already finished.');
        return;
      }
  
      const userRef = doc(db, 'users', user.uid);
      const interactionRef = doc(
        db,
        'courses',
        user.courseID,
        'modules',
        moduleID,
        'lessons',
        contentID,
        'interactions',
        user.uid
      );
      const batch = writeBatch(db);
      const newFinishedLesson = {
        lessonID: contentID,
        ...lessonStats, // This has your test result (e.g., wordlistScore)
        date: new Date().toISOString(),
      };
      batch.update(userRef, {
        finishedLessons: arrayUnion(newFinishedLesson),
        currentLessonID: lessonID,
      });
      // Instead of saving userInteractions, save the test result here:
      batch.set(interactionRef, lessonStats, { merge: true });
      await batch.commit();
    } catch (err) {
      console.error('Error finishing lesson:', err);
      setError('Failed to finish the lesson.');
    }
  };
  

  // -----------------------------------------------------
  // 6. Finish a workbook (update finishedWorkbooks in user doc)
  // -----------------------------------------------------
  const finishWorkbook = async (workbookID, workbookStats = {}) => {
    try {
      if (!user || !workbookID) return;
      const [moduleID, contentID] = workbookID.split('/');

      if (
        userCourseData.finishedWorkbooks &&
        userCourseData.finishedWorkbooks.some((w) => w.workbookID === contentID)
      ) {
        console.log('Workbook already finished.');
        return;
      }

      const userRef = doc(db, 'users', user.uid);
      const interactionRef = doc(
        db,
        'courses',
        user.courseID,
        'modules',
        moduleID,
        'workbooks',
        contentID,
        'interactions',
        user.uid
      );
      const batch = writeBatch(db);
      const newFinishedWorkbook = {
        workbookID: contentID,
        ...workbookStats,
        date: new Date().toISOString(),
      };
      batch.update(userRef, {
        finishedWorkbooks: arrayUnion(newFinishedWorkbook),
        currentWorkbookID: workbookID,
      });
      batch.set(interactionRef, userInteractions, { merge: true });
      await batch.commit();
      // The onSnapshot listener will update userCourseData in real time.
    } catch (err) {
      console.error('Error finishing workbook:', err);
      setError('Failed to finish the workbook.');
    }
  };

  // -----------------------------------------------------
  // 7. Fetch courses list from Firebase
  // -----------------------------------------------------
  const fetchCourses = useCallback(async () => {
    if (coursesFetched.current) return;
    setLoadingCourses(true);
    setCoursesError(null);
    try {
      const coursesSnapshot = await getDocs(collection(db, 'courses'));
      const fetchedCourses = coursesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCourses(fetchedCourses);
      coursesFetched.current = true;
    } catch (error) {
      console.error('Error fetching courses:', error);
      setCoursesError(error);
    } finally {
      setLoadingCourses(false);
    }
  }, []);

  // -----------------------------------------------------
  // 8. Fetch course data on initialization
  // -----------------------------------------------------
  useEffect(() => {
    if (!authLoading && user) {
      fetchUserAndCourseData();
    } else {
      setLoading(false);
    }
  }, [user, authLoading, fetchUserAndCourseData]);

  // -----------------------------------------------------
  // 9. Fetch materials
  // -----------------------------------------------------

  useEffect(() => {
    const fetchMaterials = async () => {
      setLoading(true);
      try {
        const snapshot = await getDocs(collection(db, 'materials'));
        const materialsById = snapshot.docs.reduce((acc, doc) => {
          const data = doc.data();

          // Transform Firestore objects into arrays (if needed)
          const transformed = data.content && typeof data.content === 'object'
          ? {
              ...data,
              content: Object.entries(data.content).map(([key, value]) => ({
                key,
                ...value,
              })),
            }
          : { id: doc.id, ...data };
        

          acc[doc.id] = { id: doc.id, ...transformed };
          return acc;
        }, {});

        // Use doc IDs as your keys: e.g. "appendix", "leisure", "minigames"
        setMaterials(materialsById);
      } catch (err) {
        console.error('Error fetching materials:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMaterials();
  }, []);

  

  // -----------------------------------------------------
  // 9. Provide context values
  // -----------------------------------------------------
  const contextValue = {
    selectedCourse,
    nextLesson,
    nextWorkbook,
    courses,
    fetchCourses,
    loadingCourses,
    coursesError,
    finishLesson,
    finishWorkbook,
    loading,
    error,
    materials,
    userInteractions,
    updateUserInteraction,
    setUserInteractions,
    setCurrentLessonOrWorkbookID,
    saveTestResult: async (lessonID, testResult) => {
      try {
        if (!lessonID || typeof lessonID !== 'string') {
          throw new Error('Invalid lessonID provided. It must be a string.');
        }
        if (!testResult) throw new Error('Invalid test result provided.');
        const [moduleID, contentID] = lessonID.split('/');
        if (!moduleID || !contentID) {
          throw new Error('Invalid lessonID format. Expected "moduleID/lessonID".');
        }
        const interactionRef = doc(
          db,
          'courses',
          user.courseID,
          'modules',
          moduleID,
          'lessons',
          contentID,
          'interactions',
          user.uid
        );
        const batch = writeBatch(db);
        batch.set(interactionRef, testResult, { merge: true });
        await batch.commit();
      } catch (err) {
        console.error('Error saving test result:', err.message || err);
        throw err;
      }
    },
    fetchTestResults: async (moduleID, lessonID) => {
      try {
        if (!user) throw new Error('User is not logged in.');
        if (!moduleID || !lessonID)
          throw new Error('Invalid moduleID or lessonID provided.');
        const interactionRef = doc(
          db,
          'courses',
          user.courseID,
          'modules',
          moduleID,
          'lessons',
          lessonID,
          'interactions',
          user.uid
        );
        const interactionDoc = await getDoc(interactionRef);
        return interactionDoc.exists() ? interactionDoc.data() : {};
      } catch (err) {
        console.error('Error fetching test results:', err);
        return {};
      }
    },
    fetchUserAndCourseData,
    // Expose real-time user data so UI can use updated finished arrays and purchase info
    userCourseData,
  };

  return (
    <LearningContext.Provider value={contextValue}>
      {!loading && children}
    </LearningContext.Provider>
  );
};

export default LearningContext;
