import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  getAuth, 
  onAuthStateChanged, 
  signInWithEmailAndPassword, 
  signOut 
} from 'firebase/auth';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  updateDoc, 
  setDoc, 
  onSnapshot 
} from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const auth = getAuth();
const db = getFirestore();

// Helper function to deduplicate an array of objects by a given key
const getUniqueItems = (items, key) => {
  if (!Array.isArray(items)) return [];
  return Array.from(new Map(items.map(item => [item[key], item])).values());
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = sessionStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use onAuthStateChanged to detect authentication state changes
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (!currentUser) {
        setUser(null);
        sessionStorage.removeItem('user');
        setLoading(false);
        return;
      }
      // Set up a real-time listener on the user's Firestore document
      const userRef = doc(db, 'users', currentUser.uid);
      const unsubscribeUser = onSnapshot(
        userRef,
        async (docSnap) => {
          if (docSnap.exists()) {
            try {
              const firestoreData = docSnap.data();
              // Get custom claims to determine the role
              const idTokenResult = await currentUser.getIdTokenResult();
              const role = idTokenResult.claims.role || 'user';

              // Deduplicate finished arrays
              const rawFinishedLessons = firestoreData.finishedLessons || [];
              const rawFinishedWorkbooks = firestoreData.finishedWorkbooks || [];
              const uniqueFinishedLessons = getUniqueItems(rawFinishedLessons, 'lessonID');
              const uniqueFinishedWorkbooks = getUniqueItems(rawFinishedWorkbooks, 'workbookID');

              // Compute balances
              const totalLessonsPurchased = firestoreData.totalLessonsPurchased || 0;
              const remainingLessons = totalLessonsPurchased - uniqueFinishedLessons.length;
              const totalWorkbooksPurchased = firestoreData.totalWorkbooksPurchased || 0;
              const remainingWorkbooks = totalWorkbooksPurchased - uniqueFinishedWorkbooks.length;

              // Compute global position — but always preserve “admin” (and teacher)
              let computedPosition = firestoreData.position;
              if (computedPosition !== 'admin' && computedPosition !== 'teacher') {
                if (!firestoreData.courseID) {
                  computedPosition = 'newcomer';
                } else {
                  computedPosition = (remainingLessons > 0 || remainingWorkbooks > 0)
                    ? 'activeStudent'
                    : 'inactiveStudent';
                }
              }

              // Optionally, determine nextLessonIndex for UI purposes
              const nextLessonIndex =
                uniqueFinishedLessons.length < totalLessonsPurchased
                  ? uniqueFinishedLessons.length
                  : null;

                  const userData = {
                    uid: currentUser.uid,
                    email: currentUser.email,
                    role,
                    // Add nativeLanguage here – either from Firestore or default to 'en'
                    nativeLanguage: firestoreData.nativeLanguage || 'en',
                    ...firestoreData,
                    finishedLessons: uniqueFinishedLessons,
                    finishedWorkbooks: uniqueFinishedWorkbooks,
                    totalLessonsPurchased,
                    totalWorkbooksPurchased,
                    remainingLessons,
                    remainingWorkbooks,
                    nextLessonIndex,
                    position: computedPosition,
                  };
                  

              setUser(userData);
              sessionStorage.setItem('user', JSON.stringify(userData));
              setLoading(false);
            } catch (err) {
              console.error('Error processing user data:', err);
              setError('Failed to process user data.');
              setLoading(false);
            }
          } else {
            setUser(null);
            sessionStorage.removeItem('user');
            setLoading(false);
          }
        },
        (err) => {
          console.error('Error listening to user document:', err);
          setError('Failed to listen to user document.');
          setLoading(false);
        }
      );
      return () => {
        unsubscribeUser();
      };
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const login = async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Login error:', error);
      setError('Failed to log in. Please check your credentials and try again.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    setError(null);
    try {
      await signOut(auth);
      setUser(null);
      sessionStorage.removeItem('user');
    } catch (error) {
      console.error('Logout error:', error);
      setError('Failed to log out. Please try again.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const refreshUser = async () => {
    setLoading(true);
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setUser(null);
        sessionStorage.removeItem('user');
        return;
      }
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const firestoreData = userDoc.data();
        setUser(firestoreData);
        sessionStorage.setItem('user', JSON.stringify(firestoreData));
      } else {
        setUser(null);
      }
    } catch (err) {
      console.error('Error refreshing user:', err);
    } finally {
      setLoading(false);
    }
  };

  const updateUserProfile = async (profileData) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('No user is logged in.');
    }
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      // Merge new profile data with existing fields
      await setDoc(userRef, profileData, { merge: true });
      const updatedDoc = await getDoc(userRef);
      if (updatedDoc.exists()) {
        const updatedUserData = updatedDoc.data();
        updatedUserData.uid = currentUser.uid;
        updatedUserData.email = currentUser.email;
        sessionStorage.setItem('user', JSON.stringify(updatedUserData));
        setUser(updatedUserData);
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  };

  const saveUserData = async (userId, data) => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, data, { merge: true });
      console.log('User data saved successfully!');
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  const value = {
    user,
    login,
    logout,
    loading,
    error,
    refreshUser,
    updateUserProfile,
    saveUserData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export { db };
export default AuthContext;
